// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---themes-gatsby-theme-signalwerk-src-templates-post-index-js": () => import("./../../../themes/gatsby-theme-signalwerk/src/templates/Post/index.js" /* webpackChunkName: "component---themes-gatsby-theme-signalwerk-src-templates-post-index-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---themes-gatsby-theme-signalwerk-src-pages-404-js": () => import("./../../../themes/gatsby-theme-signalwerk/src/pages/404.js" /* webpackChunkName: "component---themes-gatsby-theme-signalwerk-src-pages-404-js" */),
  "component---src-pages-00-research-index-md": () => import("./../src/pages/00--research/index.md" /* webpackChunkName: "component---src-pages-00-research-index-md" */),
  "component---src-pages-00-todo-index-md": () => import("./../src/pages/00--todo/index.md" /* webpackChunkName: "component---src-pages-00-todo-index-md" */),
  "component---src-pages-00-ui-index-md": () => import("./../src/pages/00--ui/index.md" /* webpackChunkName: "component---src-pages-00-ui-index-md" */),
  "component---src-pages-root-md": () => import("./../src/pages/root.md" /* webpackChunkName: "component---src-pages-root-md" */)
}

